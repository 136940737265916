<template>
  <validation-observer
    ref="examinationRoomTeacherSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationRoomTeacherSave"
      body-class="position-static"
      size="lg"
      centered
      title="Thêm giám thị coi thi"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="status">
          <template v-slot:label>
            Giám thị <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Giám thị"
            rules="required"
          >
            <v-select
              v-model="teacherIds"
              label="name"
              multiple
              :options="teachers"
              :reduce="option => option.id"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationRoomTeacherSave')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'ExaminationRoomTeacherSave',
  components: {
    BForm,
    BButton,
    ValidationProvider,
    BModal,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    admissionExaminationRoomId: {
      type: Number,
      default: null,
    },
    admissionSubjectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      teacherIds: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'teacher/teachers',
    }),
  },
  methods: {
    ...mapActions({
      getTeachersByOrganizationId: 'teacher/getTeachersByOrganizationId',
      createExaminationRoomTeachers: 'admissionExaminationRoomTeacher/createExaminationRoomTeachers',
    }),
    async onShow() {
      this.isLoading = true
      try {
        await this.getTeachersByOrganizationId({ organizationId: this.user.orgId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onHide() {
      this.teacherIds = []
    },
    async onSave() {
      const valid = this.$refs
        .examinationRoomTeacherSaveFormRef
        .validate()
      if (valid && this.teacherIds.length > 0) {
        this.isLoading = true
        try {
          const response = await this.createExaminationRoomTeachers({
            admissionExaminationRoomId: this.admissionExaminationRoomId,
            teacherIds: this.teacherIds,
          })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('examinationRoomTeacherSave')
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
